<template>
  <v-dialog :value="value" width="530" persistent>
    <v-card>
      <v-card-title class="error--text">
        <v-icon color="error" class="mr-2 ml-0 pl-0">mdi-alert-octagon-outline</v-icon>
        Finalize Upload
      </v-card-title>
      <v-container fluid class="px-6 pt-0">
        <v-row>
          <v-col>
            Override warnings and create promo(s) from contract "{{ selectedContract.file_name }}"?
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center">
            <v-checkbox
              v-model="acknowledged"
              :label="labelText"
              :disabled="loading"
              class="ma-auto"
              hide-details
              :ripple="false">
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer/>
        <v-btn text :disabled="loading" @click="close">
          Close
        </v-btn>
        <v-btn color="black" class="white--text" :disabled="!acknowledged || loading" :loading="loading" @click="finalize">
          Finalize
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
export default {
  data() {
    return {
      loading: false,
      acknowledged: false,
      labelText: "I understand and acknowledge that I've been notified of the issues with this contract."
    }
  },
  name: 'FinalizeDialog',
  mixins: [displayAlert],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  methods: {
    close() {
      this.$emit('closeFinalize', false)
    },
    async finalize () {
      this.loading = true
      try {
        await ContractUpload.finalizeUpload(this.selectedContract.upload_id)
        this.$emit('closeFinalize', true)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>