var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.value, width: "530", persistent: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "error--text" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2 ml-0 pl-0", attrs: { color: "error" } },
                [_vm._v("mdi-alert-octagon-outline")]
              ),
              _vm._v(" Finalize Upload ")
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "px-6 pt-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v(
                      ' Override warnings and create promo(s) from contract "' +
                        _vm._s(_vm.selectedContract.file_name) +
                        '"? '
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { "align-self": "center" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "ma-auto",
                        attrs: {
                          label: _vm.labelText,
                          disabled: _vm.loading,
                          "hide-details": "",
                          ripple: false
                        },
                        model: {
                          value: _vm.acknowledged,
                          callback: function($$v) {
                            _vm.acknowledged = $$v
                          },
                          expression: "acknowledged"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.loading },
                  on: { click: _vm.close }
                },
                [_vm._v(" Close ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "white--text",
                  attrs: {
                    color: "black",
                    disabled: !_vm.acknowledged || _vm.loading,
                    loading: _vm.loading
                  },
                  on: { click: _vm.finalize }
                },
                [_vm._v(" Finalize ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }